import React from 'react';

const MyQuestions = React.lazy(() =>
  import('./myQuestions')
);

const myQuestions = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <MyQuestions {...props} />
  </React.Suspense>
);

export default myQuestions