import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import useSocket from 'use-socket.io-client';
import { useAppGuestUserIdSelector, useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import { useAppEvenListSelector, useAppRodeoEventLoginRequiredSelector } from 'src/_common/hooks/selectors/eventSelector';
import { CRYPTO_SECRET_KEY, INSIDERODEO_EVENT_STORAGE, paginate, PER_PAGE, SELECTED_EVENT_STORAGE, SOCKET_CHANNEL, SOCKET_URL, STORAGE, URLS } from 'src/_config';
import { useEventApi } from 'src/_common/hooks/actions/event/appEventApiHook';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { useAppEventAction } from 'src/_common/hooks/actions/event/appEventActionHook';
import { useAppUserAction } from 'src/_common/hooks/actions/user/appUserActionHook';
import { useUserApi } from 'src/_common/hooks/actions/user/appUserApiHook';
// import Pagination from 'react-js-pagination';
import NoSleep from 'nosleep.js';
import FormTextAreaInput from 'src/_common/components/form-elements/textarea/textareaInput';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToaster } from 'src/_common/hooks/actions/common/appToasterHook';

interface askQuestionFormValues {
  question: string;
}

const queryString = require('query-string');

const askQuestionSchema = yup.object().shape({
    question: yup.string().required('Question is required'),
})

export default function RodeoGamePage() {

    const [loginReq, setLoginReq] = useState<any>(0)
    const eventApi = useEventApi()
    const toast = useToaster()
    const userDetails = useAppUserDetailsSelector()
    const history = useHistory()
    const guestUserId = useAppGuestUserIdSelector()
    const userAction = useAppUserAction()
    const userApi = useUserApi()
    const isInsideRodeoTokenExists = localStorage.getItem(INSIDERODEO_EVENT_STORAGE)
    const location = useLocation()

    const { watch, register, control, setValue, getValues, handleSubmit, errors } = useForm<askQuestionFormValues>({
        mode: 'onBlur',
        resolver: yupResolver(askQuestionSchema),
        defaultValues: {
            question:''
        },
    })

    const onSubmit = (values: askQuestionFormValues) => {
        var params:any = {
            question: values.question
        }
        if(!userDetails)
        {
            if(guestUserId)
            {
                params['guest_user_id']=guestUserId
            }
        }
        console.log(params)
        eventApi.callAskQuesion(params,(message: string, resp: any) => {
            if (resp) { 
                console.log(resp)
                setValue('question','')
                toast.success(message)
            }
        }, (message: string) => {
        })
    }

    const clownLanding = () => {
        var params:any = {

        }
        if(!userDetails)
        {
            if(guestUserId)
            {
                params['guest_user_id']=guestUserId
            }
        }
        eventApi.callClownLanding(params,(message: string, resp: any) => {
            if (resp) { 
                console.log(resp)
                userAction.guestUserId(resp)
                setLoginReq(resp.game_info.login_required)
            }
        }, (message: string) => {
        })
    }

    useEffect(() => {
        const parsedHash = queryString.parse(location.hash);
        console.log(parsedHash)
        if(parsedHash&&parsedHash.userToken&&parsedHash.userToken!='null')
        {
            userAction.resetGuestUserId()
            localStorage.setItem(STORAGE, parsedHash.userToken);

            userApi.callGetMe((message: string, resp: any) => {
                    history.push(URLS.HOME)
                }, (message: string, resp: any) => {
                    history.push(URLS.HOME)
                    userAction.logout()
            })
        }
    }, [])

    useEffect(()=> {
        clownLanding()
    },[])

    useEffect(() => {
        if (userDetails || loginReq==0)
        {
            history.push(`${URLS.HOME}`)
        }
        else
        {
            history.push(`${URLS.LOGIN}`)
        }
    }, [loginReq,userDetails])

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="logo-wrap-inner">
                    <img src="images/logo.png" alt="" />
                </div>
                <div className="upload-box-wrapper">
                    <div className="question-box-wrapper">
                        <h2>Ask your Question for getting rewards</h2>
                        <div className="text-area-wrap">
                        <Controller
                            control={control}
                            name="question"
                            render={({ onChange, onBlur, value, name, ref }) => (
                                <FormTextAreaInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="text"
                                    // error={errors.question}
                                    placeholder="Type your Question"
                                    rows={5}
                                />
                            )}
                            />
                            
                        </div>
                    </div>
                    <div className="btn-wrap mx-5 mt-2 mb-4">
                        {errors && errors.question && errors.question.message ? <div className="invalid-feedback">{errors.question.message}</div> : null}
                        <button type="submit" className="rkmd-btn btn-lg btn-orange ripple-effect w-100 mt-3">Submit your Question</button>
                    </div>
                </div>
                
            </form>
        </React.Fragment >
    )
}
