import { takeLatest, all } from 'redux-saga/effects';
import { SAGA_ACTIONS } from '../_config';

import {
  checkValidToken,
  getUserProfile,
  loginUser,
  loginUserWithOtp,
  logoutUser,
  updateUserProfile,
} from './userSaga';

import {

} from './commonSaga';

import { askQuestion, clownLanding, questionsList } from './eventSaga';

import {
  getCmsContent
} from './cmsSaga';


export function* sagas() {
  yield all([
    // user saga
    takeLatest(SAGA_ACTIONS.USER.DETAILS, getUserProfile),
    takeLatest(SAGA_ACTIONS.USER.LOGIN, loginUser),
    takeLatest(SAGA_ACTIONS.USER.LOGIN_WITH_OTP, loginUserWithOtp),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_PROFILE, updateUserProfile),
    takeLatest(SAGA_ACTIONS.USER.CHECK_VALID_TOKEN, checkValidToken),

    takeLatest(SAGA_ACTIONS.USER.LOGOUT, logoutUser),


    //Event Saga
    // takeLatest(SAGA_ACTIONS.EVENT.GET_GAME_SETTINGS, getGameSettings),
    // takeLatest(SAGA_ACTIONS.EVENT.SAVE_COLOR, saveColor),
    // takeLatest(SAGA_ACTIONS.EVENT.GET_EVENT_LIST, getEventList),
    // takeLatest(SAGA_ACTIONS.EVENT.GET_EVENT_GAME, getEventGameList),
    // takeLatest(SAGA_ACTIONS.EVENT.GET_EVENT_GAME_DETAILS, getEventGameDetails),
    // takeLatest(SAGA_ACTIONS.EVENT.PICK_RIDER, pickRider),
    // takeLatest(SAGA_ACTIONS.EVENT.LEADERBOARD, viewLeaderBoard),
    // takeLatest(SAGA_ACTIONS.EVENT.GET_UPCOMING_EVENT, getUpcomingEventList),
    // takeLatest(SAGA_ACTIONS.EVENT.JOIN_EVENT_GAME, joinEventGame),
    // takeLatest(SAGA_ACTIONS.TV_IMAGE.DETAILS, tvImageDetails),
    // takeLatest(SAGA_ACTIONS.EVENT.SAVE_VOTE,saveVote),
    takeLatest(SAGA_ACTIONS.EVENT.ASK_QUESTION,askQuestion),
    takeLatest(SAGA_ACTIONS.EVENT.CLOWN_LANDING,clownLanding),
    takeLatest(SAGA_ACTIONS.EVENT.MY_QUESTIONS, questionsList),
    //CMS Saga
    takeLatest(SAGA_ACTIONS.CMS.GET_CMS, getCmsContent),




  ]);
}