import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { useParams } from "react-router";
import { useEventApi } from "src/_common/hooks/actions/event/appEventApiHook";
import { useAppTvImageSelector } from "src/_common/hooks/selectors/eventSelector";
import { removeEmptyObjectKey, removeTrailingZero, SOCKET_CHANNEL, SOCKET_URL } from "src/_config";
import useSocket from 'use-socket.io-client';

export function RodeoTvImageComponent() {

    const eventApi = useEventApi()
    const tvImageSelector = useAppTvImageSelector()
    const { gameId } = useParams<any>();
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(false)
    const [isShowingAdsPromotion, setIsShowingAdsPromotion] = useState<boolean>(false)
    const [addPromotionImage, setAddPromotionImage] = useState<any>(null)
    const [addPromotionText, setAddPromotionText] = useState<any>("")
    const [defaultAdsPromotionTime, setDefaultAdsPromotionTime] = useState<number>(1000)
    const [isShowingQusetion, setIsShowingQusetion] = useState<any>(null)

    // const getTvImageDetails = (screen1: string, type: any) => {
    //     const params = {
    //         screen1, type
    //     }
    //     eventApi.callTvImageDetails(removeEmptyObjectKey(params),
    //         (message: string, resp: any) => {
    //         }, (message: string) => {
    //             console.error(`Error at API Access => ${message}`)
    //         })
    // }

    const resetCssStyle = () => {
        var element = document.getElementById('rodeo_body');
        if (element) {
            element.style.paddingTop = '0rem'
        }
    }

    //Socket Implementation start here
    const [socket] = useSocket(SOCKET_URL, {
        autoConnect: false,
    });

    useEffect(() => {
        socket.connect()
        socket.on(SOCKET_CHANNEL.TV_BOARD_IMAGE, (tvImageData: any) => {
            console.log(tvImageData)
            if (tvImageData.type == "landing_screen") {
                setIsInitialLoad(true)
            }
           
        });

        socket.on(SOCKET_CHANNEL.ADV_PROMOTION, (adsPromotionData: any) => {
            // setAddPromotionImage(adsPromotionData.question)
            // setAddPromotionText(adsPromotionData.comment)
            // resetCssStyle()
            setIsInitialLoad(false)
            console.log(adsPromotionData)
            setIsShowingAdsPromotion(true)
            setAddPromotionImage(adsPromotionData.image)
            setAddPromotionText(adsPromotionData.text)
            setIsShowingQusetion(false)
        });
        socket.on(SOCKET_CHANNEL.DISPLAY_QUESTION, (adsPromotionData: any) => {
            setAddPromotionImage(adsPromotionData.question)
            setAddPromotionText(adsPromotionData.comment)
            resetCssStyle()
            setIsInitialLoad(false)
 
            setIsShowingAdsPromotion(false)
            setIsShowingQusetion(true)
            setAddPromotionImage(adsPromotionData.question)
            setAddPromotionText(adsPromotionData.comment)
        });
        



        return () => {
            socket.disconnect()
        }
    }, [socket])

    
    useEffect(() => {
        // resetCssStyle()
    }, [])

    useEffect(() => {
        // getTvImageDetails(gameId, null)
        setIsInitialLoad(true)
        var element = document.getElementById('rodeo_body');
        if (element) {
            element.style.paddingTop = '0px'
            // element.style.marginBottom = '0px'
            // element.style.backgroundColor = '#fff'
           // element.style.backgroundImage = 'none'
        }
    }, [])

    return (
        <React.Fragment>
            {
                isInitialLoad ?
                    <div className="main-bg"
                        style={{
                            backgroundImage: `url("${'/images/tv/tv-screen-blank.jpg'}")`,
                            position: 'fixed',
                            width: '100%',
                            height: '100%',
                            backgroundSize: '100% 100%',
                            backgroundRepeat: 'no-repeat'
                        }}
                    >  
                        
                    </div> :
                    <>
                        <div className="main-bg"
                        ></div>
                        <section className="container" style={{maxWidth: '100%',paddingTop: '40px'}}>
                            <div className="logobar ">
                                <div className="border-div" />
                                <div className="border-div2" />
                                <div className="logo_left_txt">                                   
                                    {/* {isShowingAdsPromotion && addPromotionText ? addPromotionText : "Ask the clown"} */}
                                    {isShowingAdsPromotion && addPromotionText ? addPromotionText : "www.insiderodeo.com"}
                                </div>
                                <div className="logo_image"><img src="/images/tv/logo.png" className="img-responsive" /></div>
                            </div>
                            <div className="clearfix">
                            </div>
                            
                            {isShowingQusetion?
                            <div className="tv-ads-promotion">
                                <h2 className="heading_text">{addPromotionImage}</h2>
                                {addPromotionText && <h5 className="sub-heading_text ">{addPromotionText}</h5>}
                            </div>:
                            <div className="tv-ads-promotion promo-img">
                                <img className="text-center" src={addPromotionImage} alt="image" />
                            </div>
                            }
                            
                        </section> 
                    </>
            }


        </React.Fragment>
    )
}