import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL } from '../_config';
import { CallApi } from './api/callApi';

// export function* getEventList(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.GET, API_URL.EVENT.GET_EVENT_LIST, data);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             yield put({
//                 type: ACTIONS.EVENT.GET_EVENT_LIST,
//                 payload: resp.data.data
//             })
//             // yield put({
//             //     type: ACTIONS.EVENT.RODEO_EVENT_LOGIN_REQUIRED,
//             //     payload: resp.data.data
//             // })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* getEventGameList(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.POST, API_URL.EVENT.GET_EVENT_GAME, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             yield put({
//                 type: ACTIONS.EVENT.GET_EVENT_GAME,
//                 payload: resp.data.data.event_dtls
//             })
//             yield put({
//                 type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
//                 payload: resp.data.data.status
//             })
//             yield put({
//                 type: ACTIONS.EVENT.RODEO_EVENT_LOGIN_REQUIRED,
//                 payload: resp.data.data.rodeo_event_dtls.login_required
//             })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* getEventGameDetails(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.POST, API_URL.EVENT.GET_EVENT_GAME_DETAILS, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             yield put({
//                 type: ACTIONS.EVENT.GET_EVENT_GAME_DETAILS,
//                 payload: resp.data.data
//             })
//             yield put({
//                 type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
//                 payload: resp.data.data.status
//             })
//             yield put({
//                 type: ACTIONS.EVENT.RODEO_EVENT_LOGIN_REQUIRED,
//                 payload: resp.data.data.rodeo_event_dtls.login_required
//             })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* getUpcomingEventList(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.GET, API_URL.EVENT.GET_UPCOMING_EVENT, data);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* joinEventGame(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.POST, API_URL.EVENT.JOIN_EVENT_GAME, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* pickRider(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.POST, API_URL.EVENT.PICK_RIDER, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             yield put({
//                 type: ACTIONS.EVENT.SET_PICK_ID,
//                 payload: resp.data.data.id
//             })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* viewLeaderBoard(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.POST, API_URL.EVENT.LEADERBOARD, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             yield put({
//                 type: ACTIONS.EVENT.LEADERBOARD,
//                 payload: resp.data.data
//             })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* tvImageDetails(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.GET, API_URL.TV_IMAGE.DETAILS, data);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             console.log(resp)
//             yield put({
//                 type: ACTIONS.TV_IMAGE.DETAILS,
//                 payload: resp.data.data
//             })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* saveVote(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.POST, API_URL.EVENT.SAVE_VOTE, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* getGameSettings(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.GET, API_URL.EVENT.GET_GAME_SETTINGS, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             // yield put({
//             //     type: ACTIONS.EVENT.GET_EVENT_LIST,
//             //     payload: resp.data.data
//             // })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* saveColor(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.POST, API_URL.EVENT.SAVE_COLOR, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             // yield put({
//             //     type: ACTIONS.EVENT.GET_EVENT_LIST,
//             //     payload: resp.data.data
//             // })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

export function* clownLanding(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.CLOWN_LANDING, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* askQuestion(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.EVENT.ASK_QUESTION, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* questionsList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.MY_QUESTIONS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}