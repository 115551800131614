import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { URLS, APPLICATION_GLOBAL_SLUG, createMarkup, stripHtml } from "src/_config";
import { useCmsApi } from "src/_common/hooks/actions/cms/cmsApiHook";
import { toast } from "react-toastify";


export default function AboutUsPage() {

    const cmsApi = useCmsApi();
    const [content, setContent] = useState<any>()

    // const getCmsContentDetails = () => {
    //     var params = {
    //         game_slug: APPLICATION_GLOBAL_SLUG,
    //         slug: 'about-us'
    //     }

    //     cmsApi.callGetCmsContent(params, (message: string, resp: any) => {
    //         if (resp && resp.list) {
    //             setContent(resp.list)
    //         } else {
    //             setContent('')
    //         }
    //     }, (message: string) => {
    //         toast.error(message)
    //     })
    // }

    // useEffect(() => {
    //      getCmsContentDetails()
    // }, [])

    return (
        <React.Fragment>
            <h2 className="page-title text-left">
                <Link to={URLS.HOME} className="back-history">
                    <img src="/images/black-left.svg" alt="back" />
                </Link>
                <span>About </span>Us
                <hr className="color-white" />
            </h2>
            <hr className="color-white" />
            <div className="form-box-new">
                <div className="row">
                    <p className="normal-text"
                        dangerouslySetInnerHTML={createMarkup(content && content.long_description ? content.long_description : 'No content added!')}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}